<template>
  <div style="position: relative">
    <Breadcrumb :Breadcrumb="BreadcrumbCon" />

    <div class="select">
      <div style="margin: 10px 0; color: red">总质押量: {{ cpleCount }}</div>
      <div>
        <el-select
          v-model="valueArea"
          filterable
          clearable
          placeholder="请选择区号(可搜索关键字)"
          style="width: 240px; margin-right: 10px"
          v-if="internationType"
        >
          <el-option
            v-for="item in options"
            :key="item.name"
            :label="item.name + item.tel"
            :value="item.tel"
          >
          </el-option>
        </el-select>
        <el-input placeholder="请输入手机号" v-model="phone"></el-input
        ><el-input placeholder="请输入钱包地址" v-model="walletUrl"></el-input>
        <el-button type="primary" @click="SearchList">搜索</el-button
        ><el-button type="info" @click="reset">重置</el-button>
      </div>
    </div>

    <div class="table_con">
      <el-table
        :data="tableData"
        border
        style="width: 100%"
        height="100%"
        @sort-change="changeTableSort"
      >
        <el-table-column type="index" label="序号" width="80" align="center">
          <template slot-scope="scope">
            <span>{{ scope.$index + (currentPage - 1) * pageSize + 1 }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="phone"
          label="用户手机号"
          width="130"
          align="center"
        >
        </el-table-column>
        <el-table-column prop="cpleAddress" label="用户钱包地址" width="250">
        </el-table-column>
        <el-table-column
          prop="uncple"
          label="解押CPLE数量"
          :sortable="'custom'"
          width="200"
        >
        </el-table-column>
        <el-table-column prop="txid" label="Txid"> </el-table-column>
        <el-table-column
          prop="unpledgetime"
          :sortable="'custom'"
          label="解押时间"
        >
        </el-table-column>
        <el-table-column fixed="right" label="操作" width="100" align="center">
          <template>
            <div>已解押</div>
          </template>
        </el-table-column>
      </el-table>

      <Pagination
        v-show="pageLength >= 0"
        :total="pageLength"
        :limit.sync="pageSize"
        @pagination="handlePageChange"
        :page-sizes="pageSizes"
        :page="currentPage"
      />
    </div>
  </div>
</template>

<script>
import Breadcrumb from "@/components/BreadCrumb";
import Pagination from "@/components/Pagination";

import { reqUrl } from "@/api/apiUrl";
import { request } from "@/api/request";

import { showLoading, hideLoading } from "@/util/loading";
import { areaCodeData } from "@/util/areaCodeData";

import { format_time_date } from "@/util/funBusiness";
export default {
  components: {
    Breadcrumb,
    Pagination,
  },
  inject: ["reload"],
  data() {
    return {
      BreadcrumbCon: [
        {
          name: "行程上链",
        },
        {
          name: "已解押",
        },
      ], // 面包屑数据

      cpleCount: "", // 总质押量
      phone: "",
      walletUrl: "",
      valueArea: "",
      options: [],

      // 分页数据
      currentPage: 1, // 当前页
      pageLength: 0,
      pageSize: 10, //要传过去的数据 每页多少条数据
      pageSizes: [10, 20, 50, 100],

      tableData: [],

      form: {
        txid: "",
      },

      internationType: false,
      role: "",
      phoneAreaROLE: "",
    };
  },

  mounted() {
    this.options = areaCodeData;

    this.role = localStorage.getItem("ROLE");
    var strNation = this.role.includes("ROLE_nation");
    if (strNation) {
      this.internationType = true;
    }
    this.phoneAreaROLE = localStorage.getItem("phoneArea");

    showLoading();
    const opt = {
      url: reqUrl.getCplePledgeList,
      method: "POST",
      params: JSON.stringify({
        cpleAddress: "",
        cpleStatus: "2",
        nonce: "",
        order: "",
        pageNum: 0,
        pageSize: 0,
        phone: "",
        phoneArea: this.phoneAreaROLE,
        sign: "",
        token: "",
      }),
      resFunc: (res) => {
        console.log(res.data);
        if (res.data.code == 0) {
          this.tableData = res.data.data.list;
          this.pageLength = res.data.data.total;
          this.pageSize = res.data.data.pageSize;

          if (res.data.data.list.length > 0) {
            res.data.data.list.map((item) => {
              item.unpledgetime = format_time_date(item.unpledgetime);
            });

            this.cpleCount = res.data.data.list[0].sumCple;
          } else {
            this.cpleCount = 0;
          }

          hideLoading();
        }

        if (res.data.code != 0) {
          this.$notify.error({
            title: "错误",
            message: res.data.message,
          });
          hideLoading();
        }

        hideLoading();
      },
      errFunc: (err) => {
        this.cpleCount = 0;
        this.$notify.error({
          title: "错误",
          message: err,
        });
        console.log(err, "err1");
        hideLoading();
      },
    };
    request(opt);
  },

  methods: {
    // 查询
    SearchList() {
      if (this.phone || this.walletUrl || this.valueArea) {
        showLoading();
        const opt = {
          url: reqUrl.getCplePledgeList,
          method: "POST",
          params: JSON.stringify({
            cpleAddress: this.walletUrl,
            cpleStatus: "2",
            nonce: "",
            pageNum: 0,
            pageSize: 0,
            phone: this.phone,
            phoneArea: this.valueArea || this.phoneAreaROLE,
            sign: "",
            token: "",
          }),
          resFunc: (res) => {
            if (res.data.code == 0) {
              this.tableData = res.data.data.list;
              this.pageLength = res.data.data.total;
              this.pageSize = res.data.data.pageSize;

              if (res.data.data.list.length > 0) {
                res.data.data.list.map((item) => {
                  item.unpledgetime = format_time_date(item.unpledgetime);
                });

                this.cpleCount = res.data.data.list[0].sumCple;
              } else {
                this.cpleCount = 0;
              }
              hideLoading();
            }

            if (res.data.code != 0) {
              this.$notify.error({
                title: "错误",
                message: res.data.message,
              });
              hideLoading();
            }

            hideLoading();
          },
          errFunc: (err) => {
            this.$notify.error({
              title: "错误",
              message: err,
            });
            console.log(err, "err1");
            hideLoading();
          },
        };
        request(opt);
      } else {
        this.$notify.warning({
          title: "警告",
          message: "暂无可搜索项",
        });
      }
    },

    handlePageChange(data) {
      showLoading();
      const opt = {
        url: reqUrl.getCplePledgeList,
        method: "POST",
        params: JSON.stringify({
          cpleAddress: this.walletUrl,
          cpleStatus: "2",
          nonce: "",
          pageNum: data.pageIndex,
          pageSize: data.pageSize,
          phone: this.phone,
          phoneArea: this.valueArea || this.phoneAreaROLE,
          numOrder: localStorage.getItem("sortableColumn"),
          sign: "",
          token: "",
        }),
        resFunc: (res) => {
          this.tableData = res.data.data.list;
          this.pageLength = res.data.data.total;
          this.pageSize = res.data.data.pageSize;
          this.currentPage = data.pageIndex;

          hideLoading();

          if (res.data.code != 0) {
            this.$notify.error({
              title: "错误",
              message: res.data.message,
            });
            hideLoading();
          }

          if (res.data.data.list.length > 0) {
            res.data.data.list.map((item) => {
              item.unpledgetime = format_time_date(item.unpledgetime);
            });

            this.cpleCount = res.data.data.list[0].sumCple;
          } else {
            this.cpleCount = 0;
          }
        },
        errFunc: (err) => {
          this.$notify.error({
            title: "错误",
            message: err,
          });
          console.log(err, "err1");
          hideLoading();
        },
      };
      request(opt);
    },

    changeTableSort(column) {
      console.log(column.order);
      if (column.order == "ascending") {
        localStorage.setItem("sortableColumn", 1);
        showLoading();
        // 升序
        const opt = {
          url: reqUrl.getCplePledgeList,
          method: "POST",
          params: JSON.stringify({
            cpleAddress: this.walletUrl,
            cpleStatus: "2",
            nonce: "",
            order: 1,
            numOrder: 1,
            pageNum: 0,
            pageSize: 0,
            phone: this.phone,
            phoneArea: this.valueArea || this.phoneAreaROLE,
            sign: "",
            token: "",
          }),
          resFunc: (res) => {
            if (res.data.code == 0) {
              this.tableData = res.data.data.list;
              this.pageLength = res.data.data.total;
              this.pageSize = res.data.data.pageSize;

              if (res.data.data.list.length > 0) {
                res.data.data.list.map((item) => {
                  item.unpledgetime = format_time_date(item.unpledgetime);
                });

                this.cpleCount = res.data.data.list[0].sumCple;
              } else {
                this.cpleCount = 0;
              }
              hideLoading();
            }

            if (res.data.code != 0) {
              this.$notify.error({
                title: "错误",
                message: res.data.message,
              });
              hideLoading();
            }

            hideLoading();
          },
          errFunc: (err) => {
            this.$notify.error({
              title: "错误",
              message: err,
            });
            console.log(err, "err1");
            hideLoading();
          },
        };
        request(opt);
      } else if (column.order == "descending") {
        localStorage.setItem("sortableColumn", 0);
        showLoading();
        // 降序
        const opt = {
          url: reqUrl.getCplePledgeList,
          method: "POST",
          params: JSON.stringify({
            cpleAddress: this.walletUrl,
            cpleStatus: "2",
            nonce: "",
            order: 0,
            numOrder: 0,
            pageNum: 0,
            pageSize: 0,
            phone: this.phone,
            phoneArea: this.valueArea || this.phoneAreaROLE,
            sign: "",
            token: "",
          }),
          resFunc: (res) => {
            if (res.data.code == 0) {
              this.tableData = res.data.data.list;
              this.pageLength = res.data.data.total;
              this.pageSize = res.data.data.pageSize;

              if (res.data.data.list.length > 0) {
                res.data.data.list.map((item) => {
                  item.unpledgetime = format_time_date(item.unpledgetime);
                });

                this.cpleCount = res.data.data.list[0].sumCple;
              } else {
                this.cpleCount = 0;
              }
              hideLoading();
            }

            if (res.data.code != 0) {
              this.$notify.error({
                title: "错误",
                message: res.data.message,
              });
              hideLoading();
            }

            hideLoading();
          },
          errFunc: (err) => {
            this.$notify.error({
              title: "错误",
              message: err,
            });
            console.log(err, "err1");
            hideLoading();
          },
        };
        request(opt);
      }
    },

    reset() {
      if (this.phone || this.walletUrl) {
        this.phone = "";
        this.walletUrl = "";
        this.refresh();
      } else {
        this.$notify.warning({
          title: "警告",
          message: "暂无可重置项",
        });
      }
    },

    getDayTimer(data) {
      var date = new Date(data);
      var y = date.getFullYear();
      var m = date.getMonth() + 1;
      m = m < 10 ? m : m;
      var d = date.getDate();
      d = d < 10 ? d : d;
      var currentdate = y + "/" + m + "/" + d;
      var hh = date.getHours();
      hh = hh < 10 ? "0" + hh : hh;
      var mm = date.getMinutes();
      mm = mm < 10 ? "0" + mm : mm;
      var ss = date.getSeconds();
      ss = ss < 10 ? "0" + ss : ss;
      var time = hh + ":" + mm + ":" + ss;
      return currentdate + " " + time;
    },

    // 刷新
    refresh() {
      this.reload();
    },
  },

  destroyed() {
    localStorage.removeItem("sortableColumn");
  },
};
</script>

<style lang="scss" scoped>
.bread {
  height: 20px;
}

.select {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .el-select {
    margin-right: 10px;
  }

  .el-input {
    width: 250px;
    margin-right: 10px;
  }
}
</style>
